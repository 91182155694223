export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33')
];

export const server_loads = [0,7];

export const dictionary = {
		"/": [13],
		"/(privileged)/admin": [15,[2,4],[3]],
		"/(privileged)/admin/assessment-tools/form-validation": [16,[2,4,5],[3]],
		"/(privileged)/admin/assessment-tools/launch": [17,[2,4,5],[3]],
		"/(privileged)/admin/roster": [18,[2,4],[3]],
		"/(privileged)/educator": [19,[2,6],[3]],
		"/(privileged)/educator/resources": [20,[2,6,7],[3]],
		"/(privileged)/educator/resources/[slug]": [21,[2,6,7],[3]],
		"/exam/[examPlanId]": [24,[8],[,9]],
		"/exam/[examPlanId]/[resultId]/[taskItemsIndex]/[taskItemLabel]/proctor": [25,[8,,10,11],[,9]],
		"/exam/[examPlanId]/[resultId]/[taskItemsIndex]/[taskItemLabel]/student": [26,[8,,10,11],[,9]],
		"/(privileged)/landing": [22,[2],[3]],
		"/lobby": [27],
		"/(privileged)/proctor": [23,[2],[3]],
		"/signin": [28],
		"/signout": [29],
		"/student-game": [30,[12]],
		"/switch-role": [31],
		"/themes/admin": [32],
		"/themes/student": [33],
		"/(privileged)/[...path]": [14,[2],[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';